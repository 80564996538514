.underline{
    text-decoration: underline !important;
}
.linethrough{
    text-decoration: line-through !important;
}
.text-decoration-none{
    text-decoration: none !important;
}
.cursor-default{
    cursor: default !important;
}
.cursor-pointer{
    cursor: pointer !important;
}
.ellipsis{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.pre-wrap{
    white-space: pre-wrap;
}
.hidden{
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
}
.no-pointer{
    pointer-events: none;
}
.saving{
    opacity: .6;
    pointer-events: none;
}
.saving--pre{
    transition: .15s ease;
}

// font weight
.fontweight-300{
    font-weight: 300;
}
.fontweight-400{
    font-weight: 400;
}
.fontweight-500{
    font-weight: 500;
}
.fontweight-600{
    font-weight: 600;
}
.fontweight-700{
    font-weight: 700;
}

// gap
.gap-5{
    gap: 5px !important;
}
.gap-8{
    gap: 8px !important;
}
.gap-10{
    gap: 10px !important;
}
.gap-20{
    gap: 20px !important;
}
.gap-30{
    gap: 30px !important;
}

// spacer
.inline-spacer-5{
    display: inline-block;
    width: 5px;
}
.inline-spacer-10{
    display: inline-block;
    width: 10px;
}

// margins
.mb-5{
    margin-bottom: 5px !important;
}
.mb-10{
    margin-bottom: 10px !important;
}
.mb-15{
    margin-bottom: 15px !important;
}
.mb-20{
    margin-bottom: 20px !important;
}
.mb-30{
    margin-bottom: 30px !important;
}
.mb-40{
    margin-bottom: 40px !important;
}
.mt-5{
    margin-top: 5px !important;
}
.mt-10{
    margin-top: 10px !important;
}
.mt-15{
    margin-top: 15px !important;
}
.mt-20{
    margin-top: 20px !important;
}
.mt-30{
    margin-top: 30px !important;
}
.mt-40{
    margin-top: 40px !important;
}
.ml-10{
    margin-left: 10px !important;
}
.ml-15{
    margin-left: 15px !important;
}
.ml-20{
    margin-left: 20px !important;
}
.ml-40{
    margin-left: 40px !important;
}
.mr-10{
    margin-right: 10px !important;
}
.mr-15{
    margin-right: 15px !important;
}
.mr-20{
    margin-right: 20px !important;
}
.mr-40{
    margin-right: 40px !important;
}

// paddings
.pb-10{
    padding-bottom: 10px !important;
}
.pb-15{
    padding-bottom: 15px !important;
}
.pb-20{
    padding-bottom: 20px !important;
}
.pb-30{
    padding-bottom: 30px !important;
}
.pb-40{
    padding-bottom: 40px !important;
}
.pb-100{
    padding-bottom: 100px !important;
}
.pt-10{
    padding-top: 10px !important;
}
.pt-15{
    padding-top: 15px !important;
}
.pt-20{
    padding-top: 20px !important;
}
.pt-30{
    padding-top: 30px !important;
}
.pt-40{
    padding-top: 40px !important;
}
.pt-100{
    padding-top: 100px !important;
}
.pl-10{
    padding-left: 10px !important;
}
.pl-15{
    padding-left: 15px !important;
}
.pl-20{
    padding-left: 20px !important;
}
.pl-40{
    padding-left: 40px !important;
}
.pl-100{
    padding-left: 100px !important;
}
.pr-10{
    padding-right: 10px !important;
}
.pr-15{
    padding-right: 15px !important;
}
.pr-20{
    padding-right: 20px !important;
}
.pr-40{
    padding-right: 40px !important;
}
.pr-100{
    padding-right: 100px !important;
}