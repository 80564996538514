.type-stamp{
    color: darkgray;
    background-color: lightgray;
    display: inline-flex;
    align-items: center;
    padding: 0 5px;
    height: 20px;
    border-radius: 4px;
    font-size: 12px;
    line-height: 20px;
    text-transform: uppercase;
    font-weight: 500;
    max-width: 100%;
    text-decoration: none !important;
    *{ text-decoration: none !important; }
    &.type-stamp--round{
        border-radius: 99px;
    }
    &.type-stamp--admin{
        font-size: 10px;
        &.type-stamp--blue{
            background-color: var(--medbook-blue-10-pct);
        }
    }
    &.type-stamp--admin-blue{
        color: var(--primary-blue);
        background-color: var(--admin-color--5-pct);
    }
    &.type-stamp--blue{
        color: var(--primary-blue);
        background-color: var(--medbook-blue-5-pct);
    }
    &.type-stamp--blue-10{
        color: var(--primary-blue);
        background-color: var(--medbook-blue-10-pct);
    }
    &.type-stamp--green{
        color: var(--medbook-green) !important;
        background-color: var(--medbook-green-5-pct);
    }
    &.type-stamp--orange{
        color: var(--medbook-orange) !important;
        background-color: var(--medbook-orange-5-pct);
        span{
            color: var(--medbook-orange) !important;
        }
    }
    &.type-stamp--disabled{
        color: #91A2AF;
        background-color: #F7F7F7;
    }
}

.date-stamp{
    height: 26px;
    padding-left: 25px;
    padding-right: 5px;
    background-image: url(/assets/img/icons/date-blue.svg);
    background-size: 16px;
    background-position: left 5px center;
    background-repeat: no-repeat;
    background-color: var(--medbook-blue-10-pct);
    border-radius: 4px;
    font-size: 13px;
    line-height: 26px;
    color: var(--text-color);
    display: inline-flex;
    align-items: center;
    &.date-stamp--admin{
        background-image: url(/assets/img/icons/date-blue--admin.svg);
        background-size: 16px;
        background-position: left 5px center;
        background-repeat: no-repeat;
        background-color: var(--medbook-blue-10-pct);
    }
}

.time-stamp{
    height: 26px;
    padding-left: 25px;
    padding-right: 5px;
    background-image: url(/assets/img/icons/time-blue.svg);
    background-size: 16px;
    background-position: left 5px center;
    background-repeat: no-repeat;
    background-color: var(--medbook-blue-10-pct);
    border-radius: 4px;
    font-size: 13px;
    line-height: 26px;
    color: var(--text-color);
    display: inline-flex;
    align-items: center;
    &.time-stamp--admin{
        background-image: url(/assets/img/icons/time-blue--admin.svg);
        background-size: 16px;
        background-position: left 5px center;
        background-repeat: no-repeat;
        background-color: var(--medbook-blue-10-pct);
    }
}

.table-stamp{
    color: darkgray;
    background-color: lightgray;
    padding: 0 2px;
    height: 20px;
    border-radius: 4px;
    font-size: 9px;
    line-height: 20px;
    text-transform: uppercase;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    gap: 3px;
    // vertical-align: middle;
    &.table-stamp--orange{
        color: var(--medbook-orange);
        background-color: var(--medbook-orange-5-pct);
    }
    &.table-stamp--blue{
        color: var(--primary-blue);
        background-color: var(--medbook-blue-10-pct);
    }
    &.table-stamp--padded{
        padding: 0 5px;
    }
    &.table-stamp--fs-12{
        font-size: 12px;
    }
}

.stamp-content-wrap{
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}

.table-stamp-more-items-wrap, .stamp-more-items-wrap{
    display: flex;
    gap: 5px;
    .table-stamp-icon{
        color: darkgray;
        background-color: lightgray;
        height: 26px;
        padding-left: 5px;
        padding-right: 5px;
        border-radius: 4px;
        font-size: 13px;
        display: inline-flex;
        align-items: center;
        gap: 5px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        &.stamp-more-items-wrap{
            height: 25px;
            .table-stamp-more-items, .stamp-more-items{
                height: 25px;
                min-width: 25px;
            }
        }
        &.table-stamp-icon--transparent, .stamp-icon--transparent{
            padding-left: 0;
            padding-right: 0;
            background-color: transparent;
            color: var(--text-color);
            font-size: 15px;
        }
        &.table-stamp-icon--blue, .stamp-icon--blue{
            color: var(--text-color);
            background-color: var(--medbook-blue-10-pct);
        }
    }
    .table-stamp-more-items, .stamp-more-items{
        height: 26px;
        min-width: 26px;
        padding-left: 5px;
        padding-right: 5px;
        border-radius: 4px;
        font-size: 13px;
        color: var(--text-color);
        background-color: var(--medbook-blue-10-pct);
        display: inline-flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        font-weight: 500;
        cursor: default;
    }
}

.td--title-link{
    .table-stamp-more-items-wrap{
        span{
            color: var(--primary-blue);
        }
        .table-stamp-more-items{
            color: var(--primary-blue);
        }
    }
}

.subscription-badges{
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}
.subscription-badge{
    width: 26px;
    height: 26px;
    display: inline-flex;
    background-size: contain;
    background-repeat: no-repeat;
    &.subscription-badge--inactive{
        filter: saturate(0);
    }
    &.subscription-badge--assistant{
        background-image: url(/assets/img/icons/subscription-badge-student.svg);
        &.subscription-badge--passive{ background-image: url(/assets/img/icons/subscription-badge-student--passive.svg); }
    }
    &.subscription-badge--professional{
        background-image: url(/assets/img/icons/subscription-badge-professional.svg);
        &.subscription-badge--passive{ background-image: url(/assets/img/icons/subscription-badge-professional--passive.svg); }
    }
    &.subscription-badge--pro{
        background-image: url(/assets/img/icons/subscription-badge-pro.svg);
        &.subscription-badge--passive{ background-image: url(/assets/img/icons/subscription-badge-pro--passive.svg); }
    }
    &.subscription-badge--staff{
        background-image: url(/assets/img/icons/subscription-badge-staff.svg);
        &.subscription-badge--passive{ background-image: url(/assets/img/icons/subscription-badge-staff--passive.svg); }
    }
    &.subscription-badge--admin{
        background-image: url(/assets/img/icons/subscription-badge-admin.svg);
        &.subscription-badge--passive{ background-image: url(/assets/img/icons/subscription-badge-admin--passive.svg); }
    }
    &.subscription-badge--superAdmin{
        background-image: url(/assets/img/icons/subscription-badge-superadmin.svg);
        &.subscription-badge--passive{ background-image: url(/assets/img/icons/subscription-badge-superadmin--passive.svg); }
    }
}
.additional-badges{
    line-height: 26px;
    font-size: 12px;
    font-weight: 500;
    margin-left: -5px;
    margin-right: -5px;
    padding-left: 5px;
    padding-right: 5px;
    display: inline-block;
    &.additional-badges--assistant{
        color: #2EA1F8;
    }
    &.additional-badges--professional{
        color: #58D1A2;
    }
    &.additional-badges--pro{
        color: #0C7C59;
    }
    &.additional-badges--staff{
        color: #F82E2E;
    }
    &.additional-badges--admin{
        color: #0E79B2;
    }
    &.additional-badges--superadmin{
        color: #0E79B2;
    }
}

.score-match{
    display: flex;
    height: 20px;
    padding: 0 5px;
    color: #fff;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    &.score-match--green{
        background-color: var(--medbook-green);
    }
    &.score-match--orange{
        background-color: var(--medbook-orange);
    }
    &.score-match--red{
        background-color: var(--medbook-red);
    }
}